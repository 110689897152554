.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 15px;
  column-gap: 5px;
  place-items: center start;
  padding-bottom: 15px;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 15px;
  column-gap: 5px;
  place-items: center start;
  padding-bottom: 15px;
}

.grid-container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 15px;
  column-gap: 5px;
  place-items: center start;
  padding-bottom: 15px;
}

.grid-container-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 15px;
  column-gap: 5px;
  place-items: center start;
  padding-bottom: 15px;
}

.grid-container-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 15px;
  column-gap: 5px;
  place-items: center start;
  padding-bottom: 15px;
}

.grid-stat {
  text-align: left;
}

.grid-label {
  color: #0D2D50;
  font-size: 16px;
  margin-bottom: 0px;
}

.grid-value {
  color: #0156D0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 0px;
}

@media only screen and (max-width: 1000px)  {
  .grid-container-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    column-gap: 5px;
  }

  .grid-container-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    column-gap: 5px;
  }

  .grid-container-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    column-gap: 5px;
  }

  .grid-container-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    column-gap: 5px;
  }
  
  .grid-container-6 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    column-gap: 5px;
  }
}

@media only screen and (max-width: 500px)  {
  .grid-container-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .grid-container-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .grid-container-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }

  .grid-container-5 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }
  
  .grid-container-6 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
  }
}
