.ul-li {
  padding-top: 30px;
}

.grid-3-col-1-2-1fr {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}
.grid-3-col-1-9-1fr {
  display: grid;
  grid-template-columns: 1fr 9fr 1fr;
}
.grid-3-col-1-1-1fr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.grid-2-col-1-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-2-col-3vw-1fr {
  display: grid;
  grid-template-columns: 3vw 1fr;
}
.grid-4-col-1fr-2fr-1fr-1fr {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr;
}
.grid-4-col-1fr-3fr-3fr-1fr {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
}
.grid-1-1-1-1fr {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.col-gap-3vw {
  column-gap: 3vw;
}

.row-gap-6vh {
  row-gap: 6vh;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-2-desktop {
  grid-column-start: 2;
}

.row-start-2 {
  grid-row-start: 2;
}
.row-start-3 {
  grid-row-start: 3;
}
.row-start-7 {
  grid-row-start: 7;
}
.row-start-8  {
  grid-row-start: 9;
}

.padding-50px-200px {
  padding: 50px 200px;
}

.padding-bottom-3vh {
  padding-bottom: 3vh;
}

.padding-bottom-11vh {
  padding-bottom: 11vh;
}

.padding-top-3vh {
  padding-top: 3vh;
}

.padding-top-11vh {
  padding-top: 11vh;
}

.padding-top-15vh {
  padding-top: 15vh;
}

.padding-left-right-11vh {
  padding-left: 11vh;
  padding-right: 11vh;
}

.padding-left-11vh {
  padding-left: 11vh;
}

.padding-left-1vw {
  padding-left: 1.5vw;
}

.min-height-150vh {
  min-height: 150vh;
}

.min-height-80vh {
  min-height: 80vh;
}

.Flex-box {
  display: flex;
}

.Flex-box-direction-column {
  flex-direction: column;
}

.Flex-box-align-center {
  align-items: center;
  justify-content: center;
}

.Flex-box-space-evenly {
  justify-content: space-evenly;
}


.font-size-20px {
  font-size: 20px;
}

.background-grey-mobile {
  clip-path: polygon(100% 10%, 0% 0%, 0% 100%, 100% 100%);
  background-color:  #F0F1F6;
  padding-top: 25vh;
}

.hover-header:hover > h3 {
  color: #0156D0;
}

@media screen and (max-width: 600px) { 

  .font-size-20px {
    font-size: 20px;
  }
  .background-grey-mobile {
    background-color: #F0F1F6;
    clip-path: polygon(100% 2%, 0% 0%, 0% 100%, 100% 100%);
    padding-top: 15%;
  }

  .grid-1-1-1-1fr {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
  .grid-2-col-1-1fr {
    grid-template-columns: 1fr;
  }

  .grid-3-col-1-1-1fr{
    grid-template-columns: 1fr;
  }

  .grid-2-col-3vw-1fr {
    grid-template-columns: 0.5fr 1fr;
    row-gap: 41px;
  }

  .col-start-2-desktop {
    grid-column-start: auto;
  }

  .grid-4-col-1fr-2fr-1fr-1fr {
    grid-template-columns: 1fr 9fr 1fr;
  }

  .grid-4-col-1fr-3fr-3fr-1fr {
    grid-template-columns: 1fr;
    row-gap: 50px;
    padding-top: 3vh;
  }

  .row-start-auto-mobile {
    grid-row-start: auto;
  }
  .padding-50px-200px {
    padding: 20px 40px 50px;
  }

  .padding-left-30px-desktop {
    padding-left: 0;
  }
}

@media only screen and (min-width: 901px) {
  .padding-left-30px-desktop {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 1920px) { 
  .min-height-150vh {
    min-height: 1503px;
  }

  .min-height-80vh {
    min-height: 948px;
  }

 .background-grey-mobile {
    clip-path: polygon(100% 10%, 0% 0%, 0% 100%, 100% 100%);
    background-color:  #F0F1F6;
    padding-top: 200px;
  }
}

