.NotFound__image {
  background-image: url('https://cdn.catalist.co.nz/Catalist_not_found.png');
  height: 80vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: contain;
}

.NotFound__Container__button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.maintenance__image {
  background-image: url('https://cdn.catalist.co.nz/website_assets/Catalist_DownForMaintenance_NoText-01-SMALL.png');
  height: 60vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: contain;
}

.maintenance__container__text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}