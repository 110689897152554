.Server_error__image__container {
  background-image: url('https://cdn.catalist.co.nz/Catalist_500_error.png');
  height: 80vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: contain;
}

.Server_error__Container__button {
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: flex-end;
}
