.MuiTableRow-hover:hover {
  background-color: #f0f1f6 !important;
}


/* 
  Custom rules for feedback carousel 
  In order to affect carousel styles a globalized class is required.
*/

/* Fading effect for the carousel items */
.custom-carousel-1 .carousel ul li {
  opacity: 0.4;
  transition: opacity 0.5s ease;
}

.custom-carousel-1 .carousel ul li.selected {
  opacity: 1;
}

/* Arrow customization */
.custom-carousel-1 .carousel .control-arrow {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  z-index: 2;
}

.custom-carousel-1 .carousel .control-arrow.control-prev {
  left: 0;
  background-color: rgba(197, 191, 191, 0.9);
  margin-top: 4%;
}

.custom-carousel-1 .carousel .control-arrow.control-next {
  right: 0;
  background-color: rgba(197, 191, 191, 0.9);
  margin-top: 4%;
}

.custom-carousel-1 .carousel .control-arrow:before {
  font-size: 20px;
  color: rgb(5, 5, 5);
}

.custom-carousel-1 .carousel .slide img {
  width: auto !important;
  max-width: 100px !important;
  display: block !important;
  margin: 0 auto !important;
  vertical-align: middle !important;
}