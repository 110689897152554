html, body,#wrap {margin:0;	padding:0;	height:100%; scroll-behavior: smooth;}
li, ul,p,h1,h2,h3,h4,h5,h6 {
  font-family: 'Sofia-Pro';
  color: #0D2D50;
}
a {
  color: #0156D0;
}
p {
  font-size: 16px;
}

@media only screen and (max-device-width: 500px) {
  p {
     font-size: 14px;
  }
}
#wrap {display:table;width:100%}
/* if ie7 support is needed then set height of #wrap and header and footer to auto in CC's and just let it have a normal layout (or: *+html #wrap{height:auto})*/
.footer {text-align:center;}
.footer p {	margin:0;	padding:10px}

.Default_layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
a:focus {
  outline: none;
  outline-offset: 0;
}
button:focus {
  outline:none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.primary-color {
  color: #0156D0;
}

.text-grey {
  color: grey;
}

body {
  overflow: auto !important;
}

body.full-screen-modal {
  overflow: hidden !important;
}